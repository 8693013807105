<template>
  <page-container class="payment-container">
    <!--    {{ addressLoading ? '加载中~' : '加载好了' }}-->
    <div class="payment-card-item">
      <pay-address
        v-model="addressInfo"
        :loading.sync="addressLoading"
        @change="handleAddressChange"
      />
    </div>

    <div class="payment-card-item goods-info-container">
      <div class="agency-container">
        <van-icon name="shop-o" />
        {{ goodInfo.goodsOrderVOS && goodInfo.goodsOrderVOS[0].agencyName }}
      </div>
      <div v-for="(item, index) in goodInfo.goodsOrderVOS" :key="index">
        <goods-info
          view-model="small"
          :value="{
            goodsImgSrc: item.linkGoods.tagIcon,
            goodsName: item.linkGoods.goodsName,
            goodsDesc: goodsInfo.subName,
            goodsPrice: formatPrice(item.linkGoods.goodsPrice),
            tagTitle: item.linkGoods.tagTitle,
          }"
        >
          <template #price-right>
            <van-stepper
              v-if="!groupGoodsId"
              v-model="item.num"
              theme="round"
              disable-input
            />
          </template>
        </goods-info>
      </div>
    </div>
    <div class="payment-card-item">
      <van-field
        v-model="form.memo"
        type="textarea"
        rows="1"
        :autosize="true"
        :show-word-limit="true"
        maxlength="100"
        label-width="34"
        label="留言"
        placeholder="请输入留言"
      />
    </div>

    <div class="payment-card-item pay-way-container">
      <van-radio-group v-model="form.payWay">
        <van-cell-group>
          <van-cell
            v-for="pay of payWayList"
            :title="pay.text"
            clickable
            @click="form.payWay = pay.type"
          >
            <template #title>
              <van-icon :class="pay.iconName" :name="pay.iconName" />
              {{ pay.text }}
              <span
                v-if="pay.type === '04'"
                class="user-balance-display-container"
              >
                ￥<span>{{ userBalance }}</span>
              </span>
            </template>
            <template #right-icon>
              <van-radio :name="pay.type" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </div>

    <div class="payment-bottom-bar">
      <div class="total-container">
        <span>应付：</span>
        <div class="price-container">
          <div class="total-price">
            <span>{{ formatPrice(displayAllPrice) }}</span>
          </div>
          <div v-if="formatCarriage" class="carriage-price">
            (邮费：<span>{{ formatCarriage }}</span
            >)
          </div>
        </div>
      </div>
      <div class="buy-container">
        <van-button round type="primary" style="width: 100%" @click="saveOrder"
          >提交订单
        </van-button>
      </div>
    </div>

    <van-popup
        :value="replacePayDlg.show"
        :close-on-click-overlay="false"
        round position="bottom"
    >
      <van-image
          width="61.8%"
          style="margin: 0 19.1%"
          :src="replacePayDlg.payQrCodeUrl"
      />

      <p v-if="form.payWay === '0A'" style="text-align: center; color: #666">
        请用微信或支付宝扫描二维码，完成他人代付
      </p>
      <p v-if="form.payWay === '0B'" style="text-align: center; color: #666">
        请用微信扫描二维码，完成快捷代付
      </p>
      <div style="margin: 1.3em">
        <van-row gutter="20">
          <van-col span="12">
            <van-button round block type="primary" @click="goOrderListPage">
              支付完成
            </van-button>
          </van-col>
          <van-col span="12">
            <van-button round block @click="goOrderListPage">
              取消支付
            </van-button>
          </van-col>
        </van-row>
      </div>
    </van-popup>

    <fast-cards-pay
        :show.sync="fastCardsPayDlg.show"
        :order-id="fastCardsPayDlg.orderId"
        :is-first-pay.sync="fastCardsPayDlg.isFirstPay"
        :closeable="false"
        @success="() => goOrderListPage(true)"
        @cancel="goOrderListPage"
    />


  </page-container>
</template>

<script>
import { userBalancePayment } from "@/api/normalOrder";
import {
  getCartGoodsInfo,
  getCartOrderAreaPostage,
  saveCartOrder,
  payOrder,
} from "@/api/shoppingCart";
import PayAddress from "@/views/payment/payAddress";
import { mapState } from "vuex";
import GoodsInfo from "@/components/GoodsInfo";
import { Dialog, Toast } from "vant";
import { getUserAccount } from "@/api/user";
import { DAI_FU_KUAN } from "@/const/orderStatus";
import { wxJsPay } from "@/util/wxJsPay";
import { objectGetter } from "@/util";
import qrcode from 'qrcode';
import FastCardsPay from '@/components/FastCardsPay.vue';

export default {
  name: "payment",
  components: { FastCardsPay, GoodsInfo, PayAddress },
  data() {
    return {
      cartIds: "", // 购物车选择商品id
      goodInfo: {}, // 购物车选中结算的商品
      goodsIds: [], // 商品id
      provinceCode: "",
      normalGoodsId: "",
      groupGoodsId: "",

      addressLoading: true,
      loading: true,

      userInfo: {},
      addressInfo: {},
      orderInfo: {},
      goodsInfo: {},

      tagId: null,

      form: {
        memo: "", // 留言
        payWay: "06", // 支付方式
      },
      tagTitle: "",

      userBalance: "", // 用户余额
      // 支付方式数据
      payWayList: [
        {
          iconName: "wechat",
          text: "微信支付",
          type: "01",
        },
        {
          iconName: 'credit-pay',
          text: '快捷支付',
          type: '06',
        },
        {
          iconName: 'card',
          text: '快捷代付',
          type: '0B',
        },
        {
          iconName: "gold-coin",
          text: "余额支付",
          type: "04",
        },
        // {
        //   iconName: 'friends',
        //   text: '他人代付',
        //   type: '0A',
        // },
      ],

      replacePayDlg: {
        show: false,
        payQrCodeUrl: '',
      },

      fastCardsPayDlg: {
        show: false,
        orderId: null,
        isFirstPay: true,
      },

    };
  },
  computed: {
    ...mapState({
      storeRefreshing: (state) => state.payment.refreshing,
    }),
    // 商品价格
    Price() {
      let sum = 0;
      if (this.goodInfo.goodsOrderVOS) {
        this.goodInfo.goodsOrderVOS.forEach((item) => {
          sum += item.num * item.linkGoods.goodsPrice;
        });
      }
      return sum;
    },
    displayAllPrice() {
      return this.Price + this.formatCarriage;
    },
    // 邮费text
    carriageText() {
      let { carriage } = this.form;
      return carriage ? `(含邮费：<span>${carriage}</span>)` : "";
    },
    formatCarriage() {
      let carriage = 0;
      if (this.goodInfo.goodsOrderVOS) {
        this.goodInfo.goodsOrderVOS.forEach((item) => {
          carriage += item.num * item.carriage;
          item.amount =
            item.num * item.linkGoods.goodsPrice + item.num * item.carriage;
        });
      }
      return +carriage.toFixed(2) || 0;
    },
  },
  created() {
    this.cartIds = this.$route.query.cartIds;
    this.goodsIds = JSON.parse(this.$route.query.goodsIds);
    this.init();
  },
  methods: {
    init() {
      let selectList = JSON.parse(this.$route.query.selectList);
      getCartGoodsInfo(this.cartIds).then((res) => {
        console.log(selectList);
        selectList.forEach((item) => {
          res.goodsOrderVOS.forEach((ele) => {
            if (
              ele.linkGoods.goodsId === item.goodsId &&
              ele.linkGoods.tagTitle === item.specTitle
            ) {
              ele.linkGoods.goodsPrice =
                ele.linkGoods.memberPrice || ele.linkGoods.price;
              ele.linkGoods.num = item.count;
              ele.linkGoods.tagIcon = item.tagIcon;
            }
          });
        });
        this.goodInfo = res;
        if (this.provinceCode) {
          this.handleAddressChange({ provinceCode: this.provinceCode });
        }
        getUserAccount().then((res) => {
          res = res || {};
          this.userBalance = res.amount || 0;
        });

        this.loading = false;
      });
    },
    handleAddressChange(address) {
      let { provinceCode } = address;
      this.provinceCode = provinceCode;
      // 商品id参数设置
      if (provinceCode) {
        // 邮费查询
        getCartOrderAreaPostage({
          areaId: provinceCode,
          goodsIds: this.goodsIds,
        }).then((res) => {

            if (this.goodInfo.goodsOrderVOS) {
              this.goodInfo.goodsOrderVOS.forEach((item) => {
                item.carriage = 0;
                if(res){
                  Object.getOwnPropertyNames(res).forEach((key) => {
                  if (item.linkGoods.goodsId + "" == key) {
                    item.carriage = res[key];
                  }
                });

                }

              });
            }
        });
      } else {
      }
    },
    isToCheckTagByTip() {
      return new Promise((resolve) => {
        if (this.tagId) resolve(false);
        else
          Dialog.confirm({
            title: "您尚未选择商品规格，是否选择？",
            confirmButtonText: "去选择",
            cancelButtonText: "不选择",
            beforeClose: (action, done) => {
              resolve(action === "confirm");
              done();
            },
          });
      });
    },
    // 提交订单
    async saveOrder() {
      // this.goodInfo.goodsOrderVOS.forEach(item=>{
      //   item.amount=item.num*item.linkGoods.goodsPrice
      //   item.linkGoods.tagIcon=item.num*item.linkGoods.imageUrl
      // })
      if (this.loading || this.addressLoading) return console.log("loading~~");

      const { goodsTags } = this.goodsInfo;
      // if (goodsTags && goodsTags.length) {
      //   const isToCheckTag = await this.isToCheckTagByTip();
      //   if (isToCheckTag) return;
      // }

      let {
        orderInfo,
        addressInfo,
        form,
        displayAllPrice,
        userInfo,
        goodsInfo,
        tagId,
        goodInfo,
      } = this;

      // if (userInfo.status === '01') {
      //   Dialog.alert({
      //     message: `请先完善个人信息，再购买商品`,
      //     confirmButtonText: '去完善',
      //   }).then(() => {
      //     this.$router.replace('/userInfo');
      //   });
      //   return;
      // }

      let otherForm = {
        amount: displayAllPrice.toFixed(2),
        revicerProvinceCode: addressInfo.provinceCode,
        revicerProvince: addressInfo.provice,
        revicerCityCode: addressInfo.cityCode,
        revicerCity: addressInfo.city,
        revicerCountryCode: addressInfo.countryCode,
        revicerCountry: addressInfo.country,
        revicerStreetCode: addressInfo.streetCode,
        revicerStreet: addressInfo.street,
        revicerAddress: addressInfo.address,
        revicerMobile: addressInfo.mobile,
        revicerName: addressInfo.name,
      };

      // let { normalGoodsId, groupGoodsId } = this;
      // let service = null;
      // if (normalGoodsId) {
      //   service = normalSaveOrder;
      // }
      // if (groupGoodsId) {
      //   service = groupSaveOrder;
      //   Object.assign(otherForm, {
      //     refereeId: GET_LOGIN_REFEREE_ID(),
      //   });
      // }

      let toast = Toast.loading({
        message: "创建订单中...",
        forbidClick: true, // 禁用背景点击
      });

      // let order_info = orderInfo;
      // if (order_info && order_info.linkGoods) {
      //   if (tagId)
      //     order_info.linkGoods.tagId = tagId;
      //   else
      //     delete order_info.linkGoods.tagId;
      // }
      saveCartOrder({
        ...goodInfo,
        ...form,
        ...otherForm,
      }).then(async (order) => {
        if (order && order.id) {
          // let otherAttribute = goodsInfo.otherAttribute; // 支付合同判断参数
          // let closeRule = goodsInfo.closeRule; // 支付合同判断参数

          // if (goodsInfo.name.includes('七天告白')) {
          //   await signContract7_3(order.orderNo); //合同7-3
          // }

          // if (closeRule === 2) {
          //   await signContract7_1(order.orderNo); //合同7-1
          // }

          // if ((otherAttribute & (1 << 5)) > 0) {
          //   await signContract7_2(order.orderNo); //合同7-2
          // }

          // // 创建6-1合同
          // create6_1({ orderId: order.id });

          // console.log(`订单创建成功：`, res);
          toast.clear();
          if (this.form.payWay === "01") {
            wxJsPay(order.orderNo, "payOrder").then((res) => {
              let isPaySuccess = res === true;
              if (isPaySuccess) {
                Toast("微信支付成功");
              }
              this.goOrderListPage(isPaySuccess);
            });
          } else if (this.form.payWay === '04')
            this.payment(order);
          else if (['0A', '0B'].includes(this.form.payWay))
            this.replacePay(order);
          else if (this.form.payWay === '06')
            Object.assign(this.fastCardsPayDlg, {
              show: true,
              orderId: order.id
            });
        }
      });
    },
    // 支付
    payment(order) {
      Dialog.confirm({
        title: `是否确认付款`,
        // message: '弹窗内容',
        confirmButtonText: "确认",
        beforeClose: (action, done) => {
          // 点击确认支付
          if (action === "confirm") {
            // // 微信支付
            // if (this.form.payWay === '01') {
            //   wxJsPay(order.id).then(res => {
            //     let isPaySuccess = res === true;
            //     if (isPaySuccess) {
            //       Toast('微信支付成功');
            //     }
            //     this.goOrderListPage(isPaySuccess);
            //   });
            //
            //   done();
            // }

            // 余额支付
            if (this.form.payWay === "04") {
              let toast = Toast.loading({
                message: "余额支付中...",
                forbidClick: true,
              });
              done();

              payOrder(order.orderNo, {
                headers: { getAllResponse: true },
              }).then((res) => {
                // toast.clear();
                let { code, msg } = objectGetter(res, "data") || {};
                let isPaySuccess = code === 0;
                if (isPaySuccess) {
                  Toast("余额支付成功");
                } else {
                  Toast(msg || "支付失败.");
                }
                this.goOrderListPage(isPaySuccess);
              });
            }
          } else {
            // 点击取消，页面back
            this.goOrderListPage();
            done();
          }
        },
      }).catch(() => {});
    },

    replacePay(order) {
      const name = this.form.payWay === '0A' ? '他人代付' : '快捷代付';
      let toast = Toast.loading({
        message: `${name}订单创建中...`,
        forbidClick: true, // 禁用背景点击
      });
      payOrder(order.orderNo, { headers: { getAllResponse: true } }).then(res => {
        toast.clear();
        let { code, data, msg } = objectGetter(res, 'data') || {};
        let isPaySuccess = code === 0;
        if (isPaySuccess) {
          qrcode.toDataURL(data.payUrl || '无效的二维码，payUrl is undefined', (err, payQrCodeUrl) => {
            Object.assign(this.replacePayDlg, {
              show: true,
              payQrCodeUrl,
            });
          });
        } else {
          Toast(msg || '支付失败.');
        }
      });
    },

    goOrderListPage(isPaySuccess = false) {
      this.$store.commit("orderList/setRefreshing", true);
      this.$router.replace({
        path: "/orderList",
        query: {
          status: isPaySuccess ? "" : DAI_FU_KUAN,
        },
      });
    },
    formatPrice(price) {
      price = price || 0;
      price = parseFloat(price).toFixed(3);
      return price.substring(0, price.length - 1);
    },
    // 判断并获取商品类型对应的id名及值
    getFormatGoodsObj() {
      let { normalGoodsId, groupGoodsId } = this;
      let goodsIdKey = "";
      let goodsId = "";
      if (normalGoodsId) {
        goodsIdKey = "goodsId";
        goodsId = normalGoodsId;
      }
      if (groupGoodsId) {
        goodsIdKey = "groupId";
        goodsId = groupGoodsId;
      }

      return {
        key: goodsIdKey,
        value: goodsId,
      };
    },
  },
};
</script>

<style lang="less" scoped>
.payment-container {
  padding: 0 0 54px 0;

  /deep/ .van-stepper__minus,
  /deep/ .van-stepper__plus {
    width: 20px;
    height: 20px;
  }

  .payment-card-item {
    margin: 10px;
    background-color: #fff;
    border-radius: 15px;
    overflow: hidden;
  }

  .goods-info-container {
    padding: 15px;

    .agency-container {
      display: flex;
      align-items: center;
      margin: 4px 0 14px 0;
      line-height: 1.2;
      font-size: 16px;
      color: #333;

      .van-icon {
        margin-right: 8px;
        font-size: 18px;
      }
    }
  }

  .goods-tags-container-new {
    padding: 10px 0 15px 15px;

    & > p {
      padding-bottom: 10px;
      font-size: 13px;
      color: #666;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      list-style: none;

      li {
        display: flex;
        align-items: center;
        margin-right: 20px;
        padding: 3px 0;
        max-width: 95%;

        .van-tag--large {
          display: block;
          padding: 4px 12px;
          text-align: justify;
          font-size: 13px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .van-tag--plain {
          background-color: rgba(@primary-color, 0.04);
        }

        .van-tag--default {
          color: #2b2b2b;
          background-color: #f2f2f2;
        }

        .van-image {
          flex-shrink: 0;
          margin-right: 10px;
          width: 32px;
          height: 32px;
        }
      }
    }
  }

  //.goods-tags-container {
  //  display: flex;
  //  flex-wrap: nowrap;
  //  padding: 0 40px 0 0;
  //  overflow-x: auto;
  //
  //  .goods-tags-wrap {
  //    padding: 10px 0;
  //    width: 25%;
  //    flex-basis: auto;
  //    flex-shrink: 0;
  //
  //    .van-icon-certificate {
  //      display: none;
  //    }
  //
  //    &.tag-checked {
  //      position: relative;
  //      background-color: rgba(@primary-color, .05);
  //      border-radius: 10px;
  //
  //      .van-icon-certificate {
  //        display: block;
  //        position: absolute;
  //        z-index: 1;
  //        top: 4px;
  //        right: 4px;
  //        font-weight: bolder;
  //        font-size: 20px;
  //        color: @primary-color;
  //      }
  //
  //    }
  //
  //    .goods-tags-icon-wrap {
  //      position: relative;
  //      padding-top: 100%;
  //      width: 100%;
  //
  //      .van-image {
  //        position: absolute;
  //        z-index: 1;
  //        top: 15%;
  //        left: 15%;
  //        width: 70%;
  //        height: 70%;
  //      }
  //    }
  //
  //    .goods-tags-title-wrap {
  //      text-align: center;
  //      overflow: hidden;
  //      white-space: nowrap;
  //      text-overflow: ellipsis;
  //      font-size: 14px;
  //    }
  //  }
  //}

  .pay-way-container {
    .van-icon {
      margin-right: 10px;
      font-size: 26px;
    }

    .wechat {
      color: #07c160;
    }

    .gold-coin, .friends, .credit-pay, .card {
      color: @primary-color;
    }

    .van-hairline--top-bottom::after,
    .van-hairline-unset--top-bottom::after {
      border: none;
    }

    .van-cell__title {
      display: flex;
      align-items: center;
    }

    .user-balance-display-container {
      margin-left: 1em;
      font-size: 14px;
      color: #666;
    }
  }

  .payment-bottom-bar {
    position: fixed;
    z-index: 2;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    width: 100%;
    height: 54px;
    background-color: #fff;

    .total-container {
      display: flex;
      height: 100%;

      & > span {
        display: flex;
        align-items: center;
      }

      .price-container {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .total-price {
          display: flex;
          align-items: center;
          line-height: 1.2;
          font-size: 14px;
          color: #000;

          & > span {
            font-size: 23px;
            font-weight: bolder;
            color: #e93b3d;

            &:before {
              content: "¥ ";
              font-size: 13px;
              font-weight: normal;
            }
          }
        }

        .carriage-price {
          display: flex;
          align-items: center;
          margin-left: 4px;
          line-height: 1;
          color: #666;
          font-size: 14px;

          & > span:before {
            content: "¥";
            font-size: 12px;
            font-weight: normal;
          }
        }
      }
    }

    .buy-container {
      width: 120px;
      font-size: 14px;
      font-weight: bolder;
    }
  }
}
</style>
